<template>
  <div class="natural lighten-4 fill-height">
    <ProfileAppBar title="เพิ่มเพื่อน" :backward="false"/>
    <v-main>
      <v-container fluid >
        <EmptyFriendStateCard />
        <!-- <FriendList v-else :friends="friends"/> -->
        
        <v-row v-if="state===false">
          <v-col cols="12" class="text-center">
            <v-btn outlined style="border-radius: 6px;" large color="primary" width="163"><v-icon>mdi-account-plus-outline</v-icon>เพิ่มเพื่อน</v-btn>
          </v-col>
        </v-row>
     </v-container>
       
        </v-main>
      
    
  </div>
</template>

<script>
import ProfileAppBar from "@/components/common/ProfileAppBar";
import EmptyFriendStateCard from "@/components/addfriend/EmptyFriendStateCard"
//import FriendList from "@/components/addfriend/FriendList"
export default {
  name: "Home",
  components: {
    ProfileAppBar,
    EmptyFriendStateCard,
    //FriendList
  },
  data() {
    return {
             state: false,
             friends: [{title: 'ชุมชน A'}]
    }
  },
  mounted () {
    setTimeout(() => {
          this.state = true
        }, 3000);
  },
};
</script>

<style lang="scss" scoped>


</style>